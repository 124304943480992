import React from 'react';
import './PrintBill.css';

function PrintBill({ orders, tableId }) {
  const order = orders[0];
  const date = new Date().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });

  const { subtotal, cgst, sgst, totalWithGst, discount, finalTotal } = order.calculatedTotals;

  const calculateItemTotal = (item) => {
    return Math.round(item.totalPerItem || 0);
  };

  return (
    <div className="print-bill">
      <div className="bill-header">
        <p className="mc-no">M/C NO 1</p>
        <h1>HOTEL MADHUBAN</h1>
        <p>JALNA</p>
        <p>FSSAI 11516345000363</p>
        <p className="separator">. . . . TAX/ INVOICE . . . .</p>
        <p>TABLE {order.tableNumber}</p>
        <p>WAITER {order.staffName}</p>
        <p>BILL NO: {order.billNumber}      DATE: {date}</p>
        <p className="separator">. . . . . . . ORIGINAL COPY . . . . . ..</p>
      </div>
      <table className="bill-items">
        <thead>
          <tr>
            <th>DESCRIPTION</th>
            <th>QTY</th>
            <th>RATE</th>
            <th>AMOUNT</th>
          </tr>
        </thead>
        <tbody>
          {order.orderItems.map((item, index) => (
            <tr key={index}>
              <td>{item.menuItem.name.toUpperCase()}</td>
              <td>{item.quantity.toFixed(2)}</td>
              <td>{item.calculatedPricePerItem.toFixed(2)}</td>
              <td>{calculateItemTotal(item).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="bill-summary">
        <p className="separator">. . . . . . . . . . . . . . . . . . . . .</p>
        <p>SUB-TOT ITM: {order.orderItems.length} {subtotal}</p>
        <p className="separator">. . . . . . . . . . . . . . . . . . . . .</p>
        <p>CGST @ 2.50% ON {subtotal} {cgst}</p>
        <p>SGST @ 2.50% ON {subtotal} {sgst}</p>
        <p className="separator">. . . . . . . . . . . . . . . . . . . . .</p>
        <p>TOTAL GST {cgst + sgst}</p>
        <p className="total">BILL TOT &lt;ROUNDED&gt; {finalTotal}</p>
        <p>CASH {finalTotal}</p>
      </div>
      <div className="bill-footer">
        <p>SET NO: {order.setNumber}</p>
        <p>GST-NO: 27AAAFH8828F1ZB</p>
        <p className="mc-no">M/C NO 1</p>
      </div>
    </div>
  );
}

export default PrintBill;